import(/* webpackMode: "eager" */ "/vercel/path1/apps/web/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/apps/web/src/components/dots/dots.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSelect"] */ "/vercel/path1/apps/web/src/components/footer/theme-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuContentAuthed","MenuContentGuestAuth"] */ "/vercel/path1/apps/web/src/components/navbar/profile-menu/profile-menu.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeamDropdownTrigger","TeamDropdownClient"] */ "/vercel/path1/apps/web/src/components/team-dashboard/navbar/team-dropdown/team-dropdown.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path1/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
